<template>
	<div class="newsAndTrends">
		<div class="newsAndTrends-top-img">
			<img v-lazy="topImg" :key="topImg" alt="新闻详情" />
		</div>
		<div class="container">
			<div class="content">
				<div class="inner">
					<div class="content-header">
						<DateSelect />
						<el-input
							placeholder="请输入您需要搜索的内容"
							v-model="params.keyword"
							@input="inputKeyword"
						>
							<i
								slot="suffix"
								class="el-input__icon el-icon-search"
							></i>
						</el-input>
					</div>
					<div class="content-center" v-show="!isEmpty">
						<div
							class="content-center-c"
							v-if="!isMobileEnd"
							v-show="newsTotal"
						>
							<div
								class="card"
								v-for="i in cardList"
								:key="i.id"
								@click="getnewsAndTrends(i.id)"
							>
								<div class="card-img">
									<img
										v-lazy="i.bgImg"
										:key="i.bgImg"
										alt="新闻简述图"
									/>
								</div>
								<div class="card-bottom">
									<div class="card-bottom-title">
										{{ i.title }}
									</div>
									<p class="card-time">
										<i class="iconfont icon-time"></i>
										<span>{{ i.time }}</span>
									</p>
									<p class="card-text">{{ i.sketch }}</p>
								</div>
							</div>
							<div class="cardDome"></div>
							<div class="cardDome"></div>
						</div>
						<div
							class="content-center-768c"
							v-else
							v-show="newsTotal"
							v-lazy:background-image="containerBgUrl"
						>
							<div
								class="content-center-768c-card"
								v-for="i in cardList"
								:key="i.id"
							>
								<div class="content-center-768c-card-content">
									<div
										class="content-center-768c-card-content-title"
									>
										{{ i.title }}
									</div>
									<div
										class="content-center-768c-card-content-sketch"
									>
										{{ i.sketch }}
									</div>
									<div
										class="content-center-768c-card-content-btn"
										@click="getnewsAndTrends(i.id)"
									>
										查看更多
									</div>
								</div>
								<div class="content-center-768c-card-img">
									<img
										v-lazy="i.bgImg"
										:key="i.id"
										alt="新闻简述图"
									/>
								</div>
								<div class="content-center-768c-card-bar"></div>
							</div>
						</div>

						<div class="empty" v-show="!newsTotal">
							<img
								v-lazy="nullImg"
								:key="nullImg"
								alt="请求失败"
							/>
							<span>请求数据为空</span>
						</div>
						<el-pagination
							small
							layout="prev, pager, next"
							:total="newsTotal"
							:page-size.sync="params.pageNum"
							@current-change="currentChange"
							:current-page="currentPage"
						>
						</el-pagination>
					</div>
					<div class="empty" v-show="isEmpty">
						<img v-lazy="nullImg" :key="nullImg" alt="请求失败" />
						<span>网络开小差了,新闻列表请求失败</span>
					</div>
				</div>
			</div>
			<div class="left"></div>
			<div class="right"></div>
		</div>
	</div>
</template>

<script>
import { getNewsList } from '@/network/home/index'
import DateSelect from './childCpn/dateSelect'
export default {
	name: 'newsAndTrends',
	data() {
		return {
			isEmpty: true,
			topImg: require('@/assets/img/news/banner.jpg'),
			nullImg: window.imgUrl + '/04_null.png',
			containerBgUrl: require('@/assets/img/containerBg.png'),
			defaultTime: '',
			timeValue: '',
			pickerOptions: {
				disabledDate(time) {
					let timeSpace = time.getTime() > Date.now() //时间大于今天的日期不可选择
					return timeSpace
				},
			},
			yearValue: '',
			pickerOptionsYear: {
				disabledDate(time) {
					var date = new Date()
					var y = date.getFullYear()
					return time.getFullYear() > y
				},
			},
			monthValue: '',
			pickerOptionsMonth: {
				disabledDate(time) {
					var date = new Date()
					var m = date.getMonth()
					return time.getMonth() > m
				},
			},
			cardList: [],
			newsTotal: 0,
			params: {
				keyword: '',
				page: 1,
				pageNum: 9,
				startTime: '',
				endTime: '',
			},
			currentPage: 1,
		}
	},
	watch: {},
	created() {
		this.defaultTime = new Date()
		this.defaultTime.setMonth(new Date().getMonth() - 1)
	},
	methods: {
		currentChange(e) {
			this.currentPage = e
			this.params.page = e
			this.j_getNewsList(this.params)
		},
		//点击跳转查看新闻详情
		getnewsAndTrends(id) {
			if (id) {
				this.$router.push({
					path: '/newsDetails',
					query: {
						id,
					},
				})
			}
		},
		//获取新闻列表
		j_getNewsList(data) {
			return new Promise((resolve, reject) => {
				getNewsList(data).then((res) => {
					if (res.data.code === 200) {
						this.isEmpty = false
						let list = res.data.data.list
						list.map((item) => {
							item.bgImg = window.baseUrl + item.bgImg
						})
						this.cardList = list
						this.newsTotal = res.data.data.total
						resolve()
					} else {
						this.$message.warning(res.data.msg)
						reject()
					}
				})
			})
		},
		//用户选择时间触发
		dateChange(obj) {
			let myDate = new Date()
			let year = myDate.getFullYear() //获取当前年
			let month = myDate.getMonth() + 1 //获取当前月
			let day = myDate.getDate() //获取当前日
			if (obj.year == year) {
				this.params.startTime = obj.year + '-' + '01' + '-' + '01'
				this.params.endTime = obj.year + '-' + month + '-' + day
			} else if (obj.year) {
				this.params.startTime = obj.year + '-' + '01' + '-' + '01'
				this.params.endTime = obj.year + 1 + '-' + '01' + '-' + '01'
			} else {
				//当obj.year=0时，默认等于全部
				this.params.startTime = ''
				this.params.endTime = ''
			}

			this.j_getNewsList(this.params)
		},
		inputKeyword() {
			this.j_getNewsList(this.params)
		},
	},
	components: {
		DateSelect,
	},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},
	mounted() {
		this.isMobileEnd ? (this.params.pageNum = 6) : (this.params.pageNum = 9)
		this.j_getNewsList(this.params)
	},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.newsAndTrends {
	.newsAndTrends-top-img {
		width: 100%;
		height: 6.76rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
	// 三栏都需要浮动
	.center,
	.left,
	.right {
		float: left;
	}
	// 左右两栏固定宽度
	.left,
	.right {
		width: 3.15rem;
		background-color: #fff;
	}
	// 中间栏  因为需要自适应所以设置宽度100%
	.center {
		width: 100%;
	}
	.left {
		margin-left: -100%;
	}
	.right {
		margin-left: -3.15rem;
	}
	.inner {
		margin: 0 3.15rem;
		position: relative;
	}
	.content {
		padding: 0.8rem 0;
		.content-header {
			height: 0.48rem;
			margin-bottom: 0.6rem;
			display: flex;
			justify-content: space-between;
			.el-input {
				width: 8.7rem;
				border-radius: 0.08rem;
				::v-deep .el-input__inner {
					height: 0.48rem;
					line-height: 0.48rem;
					font-size: 0.2rem;
					padding: 0 0.2rem;
				}
				::v-deep .el-input__suffix {
					right: 0.2rem;
					font-size: 0.24rem;
				}
			}
		}
		.content-center {
			.content-center-c {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.cardDome {
					width: 4.1rem;
					height: 0;
				}
				.card {
					width: 4.1rem;
					height: 3.97rem;
					overflow: hidden;
					margin-bottom: 0.6rem;
					background: #ffffff;
					border: 1px solid #e5e8f1;
					cursor: pointer;
					.card-img {
						width: 4.1rem;
						height: 2.3rem;
						img {
							width: 100%;
							height: 100%;
							display: block;
							object-fit: cover;
						}
					}
					.card-bottom {
						width: calc(100% - 0.4rem);
						height: 1.27rem;
						padding: 0.2rem;
						position: relative;
						.card-bottom-title {
							font-family: Source Han Sans, Source Han Sans CN;
							width: 100%;
							font-weight: 700;
							font-size: 0.16rem;
							color: #333333;
							line-height: 16px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.card-time {
							margin: 0.16rem 0;
							font-size: 0.14rem;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 400;
							color: #a5a7ad;
							line-height: 0.24rem;
							i {
								display: inline-block;
								margin-right: 0.05rem;
							}
						}
						.card-text {
							height: 0.52rem;
							font-size: 0.16rem;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 400;
							line-height: 0.24rem;
							color: #666666;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
					}
					&:hover {
						box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
						.card-bottom-title {
							color: #127bf2;
						}
					}
				}
			}
			.content-center-768c {
				overflow: hidden;
				&-card {
					padding: 12px;
					display: flex;
					justify-content: space-between;
					position: relative;
					margin-bottom: 1px;
					&-content {
						width: 170px;
						&-title {
							font-size: 16px;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 500;
							color: #2d3340;
							line-height: 16px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						&-sketch {
							font-size: 12px;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 400;
							color: #656b74;
							line-height: 12px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							margin: 12px 0 18px;
						}
						&-btn {
							font-size: 12px;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 400;
							color: #127bf2;
							line-height: 20px;
						}
					}
					&-img {
						width: 137px;
						height: 77px;
						-moz-border-radius: 4px;
						-webkit-border-radius: 4px;
						border-radius: 4px;
						overflow: hidden;
						img {
							width: 100%;
							height: 100%;
						}
					}
					&-bar {
						width: 182px;
						height: 1px;
						background: #e6e7ee;
						position: absolute;
						bottom: -1px;
					}
				}
			}
			.el-pagination {
				text-align: center;
				::v-deep .el-pager li.active {
					color: #127bf2;
				}
			}
		}
		.empty {
			width: 45%;
			height: 45%;
			margin: auto;
			position: relative;
			img {
				width: 100%;
				height: 100%;
				display: block;
			}
			span {
				width: 100%;
				display: inline-block;
				position: absolute;
				bottom: 20%;
				font-size: 0.18rem;
				color: #666;
				text-align: center;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.newsAndTrends {
		.newsAndTrends-top-img {
			height: 178px;
		}
		.left,
		.right {
			width: 16px;
		}
		.right {
			margin-left: -16px;
		}
		.inner {
			margin: 0 16px;
		}
		.content {
			.content-header {
				height: 28px;
				margin-bottom: 12px;
				.el-input {
					width: 183px;
					border-radius: 2px;
					::v-deep .el-input__inner {
						height: 28px;
						line-height: 28px;
						font-size: 12px;
						padding: 0 8px;
					}
					::v-deep .el-input__suffix {
						right: 8px;
						font-size: 12px;
					}
				}
			}
			.el-pagination {
				display: none;
			}
		}
	}
}
</style>
